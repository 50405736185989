<template>
  <div class="personalindex">
    <div class="header">
      <i class="el-icon-alifanhui" @click="back"></i>
    </div>
    <div class="studentInfo">
      <div class="imgBox">
        <img src="../../assets/img/studyImg.png" alt="">
      </div>
    </div>

    <ul>
      <li>
        <span class="title">姓名</span>
        <span class="content">{{studentName}}</span>
      </li>
      <li>
        <span class="title">账号</span>
        <span class="content">{{userName}}</span>
      </li>
      <li>
        <span class="title">密码</span>
        <span class="content">********</span>
      </li>
    </ul>

    <button class="changepwd" @click="changepwd">修改密码</button>
    <button class="outlogin" @click="outlogin">退出登录</button>

    <van-dialog v-model="show" title="修改密码" show-cancel-button confirmButtonText="保存" @confirm="dialogConfirm" @cancel="dialogCancel">
      <div class="dialogMain">
        <div style="height:250px;overflow: hidden;overflow-y:auto;">
          <div>
            <div>输入原密码：</div>
            <div><input type="password" id="oldpwd" /></div>
          </div>
          <div>
            <div>设置新密码：</div>
            <div><input type="password" id="newpwd" /></div>
          </div>
          <div>
            <div>再次确认新密码：</div>
            <div><input type="password" id="repeatnewpwd" /></div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import $ from 'jquery';
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      studentName: "",
      userName: "",
      studentID: "",
      show: false,
    }
  },
  methods: {
    back() {
      this.$router.push({ name: "home" });
    }, changepwd() {
      $("#oldpwd").val("");
      $("#newpwd").val("");
      $("#repeatnewpwd").val("");

      this.show = true;
    },
    outlogin() {
      this.$dialog.confirm({
        title: '提示',
        message: '是确认否退出登录',
      }).then(() => {
        Cookies.set('studentID', null, { expires: 5, path: '' });
        Cookies.set('teacherID', null, { expires: 5, path: '' });
        this.$router.push({ name: "login" });
      })
        .catch(() => {
          // on cancel

        });
    },
    dialogCancel() {

    },
    dialogConfirm() {
      var old = $("#oldpwd").val();
      var newpwd = $("#newpwd").val();
      var repeatnew = $("#repeatnewpwd").val();

      if (newpwd != repeatnew) {
        this.$dialog({ message: "两次输入的密码不匹配，请重新输入" });
        return;
      }

      let para = {
        UserID: this.studentID,
        OldPassWord: old,
        NewPassWord: newpwd,
        UserType: "Student"
      };
      this.$axios.post('/api/User/ChangePwd', para).then(res => {
        console.log(JSON.parse(res.data));
        var data = JSON.parse(res.data);
        if (data.code == 200) {
          this.$dialog({ message: "密码修改成功" });
          this.show = false;
        }
        else {
          this.$dialog({ message: data.info });
        }
      });

    }
  },
  mounted() {
    this.studentName = Cookies.get("studentName");
    this.userName = Cookies.get("userName");
    this.studentID = Cookies.get('studentID');
  },
}
</script>

<style>
.personalindex {
  position: relative;
  padding: 20px;
}
.personalindex .studentInfo {
  padding-top: 30px;
  display: flex;
}
.personalindex .imgBox {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.personalindex img {
  width: 100%;
  height: 100%;
}
.personalindex ul {
  padding-top: 20px;
}
.personalindex ul li {
  width: 100%;
  height: 50px;
  border-top: 1px solid #bbb;
  margin: 0 2.5%;
}
.personalindex ul li:last-child {
  width: 100%;
  height: 50px;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  margin: 0 2.5%;
}
.personalindex ul li .title {
  float: left;
  text-align: center;
  margin-top: 15px;
  margin-left: 30px;
  font-weight: 800;
}
.personalindex ul li .content {
  float: left;
  text-align: center;
  margin-top: 20px;
  margin-left: 55px;
  color: darkgrey;
}
.changepwd {
  position: fixed;
  width: 70%;
  height: 40px;
  background-color: white;
  border-width: 0.5px;
  border-color: #3294ff;
  border-style: double;
  color: #3294ff;
  bottom: 40%;
  left: 15%;
}
.outlogin {
  position: fixed;
  width: 70%;
  height: 40px;
  background-color: hsl(0, 80%, 65%);
  border-width: 0.5px;
  border-color: white;
  border-style: double;
  color: white;
  bottom: 30%;
  left: 15%;
}
.dialogMain {
  width: 100%;
  text-align: left;
  padding-left: 40px;
  padding-top: 20px;
}
.dialogMain div {
  padding-top: 5px;
  font-weight: 600;
  font-size: 14px;
}

.dialogMain input {
  height: 35px;
  width: 90%;
  border-color: #bbb;
  border-width: 1px;
  border-style: solid;
  font-size: 20px;
}

.header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #101010;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #f8f8f8;
  z-index: 999;
}

.header i {
  margin: 0 18px;
  font-size: 20px;
  display: block;
  width: 30px;
}
</style>